<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          :label="label"
          persistent-hint
          :prepend-icon="icons.mdiCalendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :dense="dense"
          :class="customClass"
          :hint="hint"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        type="month"
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mdiCalendar } from "@mdi/js";
import { getQueryStringMonth } from "@/utils/dates";

export default {
  props: {
    identifier: {
      type: String,
      default: "date-picker-single-month",
    },
    label: {
      type: String,
      default: "Mois",
    },
    dateInit: {
      type: String,
      default: () => {
        return "previous";
      },
      validator(value) {
        return [
          "null",
          "previous",
          "current",
          "yesterday",
          "n-month-ago",
        ].includes(value);
      },
    },
    dense: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: "Format MM/YYYY",
    },
    useGetParams: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onSelect"],
  name: "DatePickerSingleMonth",
  data: (vm) => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 7),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 7)
    ),
    menu: false,
    icons: {
      mdiCalendar,
    },
  }),
  created() {
    let d = new Date();

    if (this.useGetParams) {
      let monthDate = getQueryStringMonth(-3, 0);
      if (monthDate) {
        this.date = monthDate.toISOString().substring(0, 7);
        this.dateFormatted = this.date;
        return;
      }
    }

    if (
      this.dateInit == "previous" ||
      (this.dateInit == "yesterday" && d.getDate() === 1)
    ) {
      d = new Date(d.getFullYear(), d.getMonth() - 1, 2);

      d.setHours(-5);

      this.date = new Date(d - d.getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 7);
    } else {
      this.date = new Date(d - d.getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 7);
    }
    // this.dateFormatted = this.date;
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(val) {
      if (this.useGetParams) {
        const url = new URL(window.location.href);
        url.searchParams.set("month", val);
        window.history.replaceState(null, null, url.toString());
      }
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("onSelect", this.date, this.identifier);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}`;
    },
  },
  mounted() {
    this.$emit("onSelect", this.date, this.identifier);
  },
};
</script>

<style scoped>
/* 
[role="menu"] {
  top: 215px !important;
  right: 0px !important; 
}*/
</style>
