// source: https://jsfiddle.net/XL714/7u8ybp0t/11/
// Adapted for vue2
// Use :
/* 
import { Konami, KonamiManager } from "@/utils/konami";
export default {
    data() {
        return {
            konamiManager: null,
        }
    },
    created() {
        this.konamiManager = new KonamiManager(this, [
            new Konami([68, 69, 86], this.showSearchById), // d e v
        ]);
    },
}
*/
export class Konami {
  constructor(code, func) {
    this.code = code;
    this.func = func;
    this.curCodePos = 0;
  }
  inc() {
    this.curCodePos++;
  }
  reset() {
    this.curCodePos = 0;
  }
  str() {
    return `konami ${this.code.join(",")} => ${this.curCodePos}`;
  }
}

export class KonamiManager {
  constructor(app, konamis) {
    this.konamis = konamis;
    document.addEventListener("keydown", (event) => {
      app.konamiManager.handleKeydown(event);
    });
  }
  handleKeydown(event) {
    if (this.konamis.length === 0) return;
    if (["INPUT", "TEXTAREA"].includes(event.target.tagName)) return;
    for (const konami of this.konamis) {
      // Uncomment the following line to see the charcode while typing (if you want to create a new one)
      // console.log(`Checking konami code ${konami.code}: ${event.keyCode} === konami.code[${konami.curCodePos}]`);
      if (event.keyCode === konami.code[konami.curCodePos]) {
        konami.inc();
        if (konami.curCodePos === konami.code.length) {
          if (typeof konami.func === "function") {
            konami.func();
          }
          konami.reset();
        }
      } else {
        konami.reset();
      }
    }
  }
}
